@import 'src/assets/scss/_variables.scss';
body.kidm {
    font-family: 'Roboto', sans-serif !important;
    background-color: #f3f3f3;
    font-size: 15px;
  }
.kidm{

// buttons css start
.btn-primary {
	background-color: #2f808c !important;
	border: #2f808c !important;
	border-radius: 0 !important;
	// font-size: 13px !important;
	color: #fff !important;
	&:hover {
        box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
	}
	&:focus-visible{
		box-shadow: none !important;
}
}

// buttons css end


// authentication css end

.authentication-cards{
    a{
        color: #2f808c !important;
        font-weight: 500;
    }
    .form-check-input[type=checkbox] {
        border-color: #2f808c !important;
    }
    .form-check-input:checked {
        background-color: #2f808c;
        border-color: #2f808c;
    }
    .form-check-input:focus {
        box-shadow: none !important;
    }
}


form .form-content label {
    color: #2f808c;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}
.form-content .input-box input:focus,
.form-content .input-box input:valid {
    border-color: #2f808c;
}

form .form-content .input-box {

    input {
    }

    i {
        color: #2f808c;
    }
}

// authentication css end

// sdebar css starts
.sidenav{
    background-color: #fff;

    .logo-container{
        border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
        .logo{
            background-color: #fff;
            color: #2f808c;
        }

        .logo-text{
            color: #fff;
        }
       
    }
}

.sidenav-nav {
    border-right: 1px solid rgba(0, 0, 0, .12);
    
    .sidenav-nav-item {
        .sidenav-nav-link {
            color: rgba(0, 0, 0, 0.66) !important;

            .sidenav-link-icon {
                color: #777777;
            }

            &:hover:not(.active) {
                background-color: rgb(235 235 235) !important;
                .sidenav-link-icon, .sidenav-link-text {
                    color: #000 !important;
                }
            }
        }

        .active {
            background-color: rgba(63, 173, 189, 0.3019607843) !important;
            border-left: 5px solid #3D3C3C !important;
            .sidenav-link-icon, .sidenav-link-text {
                color: #3d3c3c !important;
            }
        }
    }
}

.custom-seperator{
    border-top: 1px solid #a4adad;
}
.sidebar:hover .sidebar-icon-open{
    background-color: #2f808c;
    color: #fff;
  }
  .sidebar-icon-open{
    &:hover{
    box-shadow: 0.063rem 0 1.25rem 0 #2f808c;
    }
  }
  .sidebar-icon-close{
    background: #2f808c;
    color: #fff;
    &:hover{
    box-shadow: 0.063rem 0 1.25rem 0 #2f808c;
  }
}
// sdebar css ends


.bg-7d2ae8{
    background-color:#2f808c ;
}
.new-db {
    color: #2f808c !important;
}
.form-control:focus {
    border-color: #2f808c;
}
.form-control-sm:focus-visible{
    border: 1px solid transparent !important;
    outline: 1px solid #2f808c !important;
}
.tabs-section .nav-tabs .nav-link.active {
    border-bottom: 2px solid #2f808c !important;
    color: #2f808c !important;
}
.tabs-section .properties .nav-tabs .nav-link.active{
    color: #fff !important;
    background-color: #2f808c !important;
    border: 0 !important;
  }
  .document-properties .nav-tabs .nav-link.active{
    color: #fff !important;
    background-color: #2f808c !important;
    border: 0 !important;
  }
.nav-tabs .nav-link:not(.active):hover {
    color: #2f808c !important;
}
// .nav-tabs .nav-link.active {
//     color: #fff !important;
//     background-color: #2f808c !important;
// }

// header css starts
.profile-dropdown {
    border: 1px solid #2f808c;
    background: #fff;
}
.logo-container .logo {
    color: #2f808c;
}
.profile-dropdown i {
    color: #2f808c !important;
}
.header-user-role .profile-dropdown {
    background-color: #2f808c !important;
    color: #fff
}

.dropdown-item:active{
    background-color: #2f808c !important;
}
// header css ends

// tabs css starts

.tabs-section .accordion-button {
    background: #2f808c !important;
    color: #fff !important;
}
// tabs css ends

.selected {
    background-color: #2f808c !important;
    color: #fff !important;
  }

  .user-access-role-scroller .bg-04ffd5 {
    background-color: #2f808c !important;
}

// radio button css starts

.custom-radio input:checked + span:before {
    box-shadow: inset 0 0 0 0.2375em #2f808c !important;
}
//radio button css ends

// multiselect css start

.multiselect-item-checkbox input[type=checkbox] + div:before {
    color: #2f808c !important;
    border: 2px solid #2f808c !important;
}
.multiselect-dropdown .dropdown-btn .selected-item {
    border: 1px solid #2f808c !important;
    background: #2f808c !important;
}
.multiselect-item-checkbox input[type=checkbox]:checked + div:before {
    background: #2f808c !important;
}
// multi select css ends

// ng select css starts
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
    color: #333;
    background-color: rgba(63, 173, 189, 0.3019607843) !important;
}
// ng select css ends

.btn-properties-description .fa-ellipsis-vertical{
    color: #2f808c !important;
}
.edit-icon {
    color: #2f808c;
}

.contact-us-link{
    color: #2f808c;
}

.selectedRow {
    background-color: #2f808c !important;
    color: #fff !important;
}

.text-decoration{
    text-decoration: underline;
    &:hover{
      color: #2f808c !important;
    }
  }
  .settings.sidebar a.active {
    background-color: #2f808c !important;
    color: #fff !important;
}

.content ul li a {
    color: #2f808c;
}
.sidebar a.active {
    background-color: #2f808c !important;
    color: #fff !important;
}

// pagination css starts
.page-link.active, .active > .page-link {
    background-color: #2f808c !important;
    border-color: #2f808c !important;
    color: #fff !important;
}
.page-link {
    color: #2f808c !important;
}
// pagination css ends

.active-view {
    background-color: #2f808c;
    color: #FFFFFF;
}
.bg-1F88E6 {
    background-color: #2f808c;
}
.breadcrumb-item a {
    color: #2f808c !important;
}
.dashboard-view-btn {
    border: 1px solid #2f808c !important;
    i{
        color: #2f808c;
    }
}
.icons-container i {
    color: #2f808c;
}

.as-horizontal > .as-split-gutter:hover {
    background-color: #2f808c !important;
}

input.default:checked + .slider {
    background-color: #2f808c;
}
.authentication-cards svg{
    display: none;
  }
  .authentication-body{
    background-image: none;
  }
  .bg-trillo {
    background-color: #fff;
    filter: brightness(1);
}

table td.bg-Blue {
    background-color: #2f808c;
}

// data manager css starts
.data-manager-table table thead th:first-child {
    background-color: #2f808c;
    color: #fff;
}
.data-manager-table table thead th:nth-child(2) {
    background-color: #2f808c;
    color: #fff;
}
.table-action-icon {
    color: #fff;
    background-color: #2f808c;
}
.data-manager-table .color-blue {
    background-color: #2f808c !important;
    color: #fff !important;
}
// data manager css ends

.list-details-accordion .card-header {
    background-color: #2f808c !important;
}
.form-select:focus {
    border-color: #2f808c;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: #c5e6eb !important;
}
.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container{
    border-color: #2f808c;
    box-shadow: none !important;
}
.attribute-textarea:focus-visible{
    outline: #2f808c !important;
}
.upload-sec {
    height: 180px;
    background-color: rgba(47, 128, 140, 0.0588235294);
    border: 1px dashed #2f808c;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.upload-text {
    color: #2f808c;
}
.upload-img{
    background-image: url('../../images/upload-green.png');
  }
  ngb-progressbar .progress-bar {
    background-color: #2f808c !important;
}
.item-right-clicked {
    outline: 1px solid rgb(47, 128, 140);
}
.input-box {
    display: flex;
    align-items: center;
    height: 50px;
    width: 100%;
    margin: 8px 0 10px 0;
    position: relative;

    input {
        border-color: rgba(47, 128, 140, 0.49);
        background: #fff;
    }
}
a {
    color: #2f808c !important;
}
 .sftp-headings{
    color: #2f808c !important;
}
.text-1F88E6{
    color: #2f808c !important;
}
.bookmark-heading{
    cursor: pointer;
    transition: 0.3s ease;
    &:hover{
        text-decoration: underline;
        color: #2f808c !important;
       
    }
}
.alert-info-background{
  padding: 16px;
  background-color:#97bfc5;

}
.trending-file-thumbnail {
    padding: 10px;
    background-color:#97bfc5;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    border-radius: 4px;
    width: 40px;
    margin-right: 10px;
}

  .search-filter-dropdown{
    border: 1px solid #2f808c !important;
    background-color: #2f808c !important;
    color: #fff;
    padding: 4px 8px;
    font-size: 13px;
    margin-left: 28px;
  }
  .btn-outline-info{
    color: #2f808c !important;
    border-color: #2f808c !important;
    &:hover , &:active{
        color: #fff !important;
        background-color: #2f808c !important;
        border-color: #2f808c !important;
      }
  }
  .sidebar-btn.delete-btn {
    background-color: #dc2f2f !important;
    border-color: #dc2f2f !important;
    border-radius: 0;
  }
  .theme-green .bs-datepicker-head {
    background-color: #2f808c;
  }
  .theme-green .bs-datepicker-body table td.week span {
    color: #2f808c;
  }
  .doc-logo{
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border: 1px solid #2f808c;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .active-card{
    background-color: #2f808c !important;
    color: #fff !important;
    border: 1px solid #2f808c !important;
  }
  .error-box {
    .error-title {
      font-size: 210px;
      color: #2f808c;
      font-weight: 900;
      text-shadow: 4px 4px 0 #fff, 6px 6px 0 #2f808c;
      line-height: 210px;
    }
  }
  .not-found-error-btn{
    color: #fff !important;
    background-color: #2f808c !important;
    border: 1px solid #2f808c !important;
  }
  .doc-response-card{
    display: inline-block;
    border: 1px solid #2f808c !important;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    padding: 5px 12px;
    background-color: #2f808c !important;
    color: #fff;
  }
  .DataExtraction-right-section .nav-tabs .nav-link.active {
    background-color: transparent !important;
}

  .highlight{
    border: 3px dotted #2f808c !important;

    .overlay{
        background: rgba(47, 129, 142, 0.239)
    }

    .fa-beat{
        color: rgb(47, 129, 142)
    }
}
.single-tab {
    border: 0.5px solid #2f808c !important;
}
.global-search {
    .query-search{
        box-shadow: rgb(47 129 140 / 20%) 0px 2px 4px 0px, rgb(47 129 140 / 20%) 0px 2px 16px 0px !important;
    }
}
.back-arrow-icon{
    color: #2f808c !important;
  }
  .loader::after {
    background: #2f808c;
  }
  .view-btn{
    border: 1px solid #2f808c !important;
    i{
      color: #2f808c !important;
    }
  }

  .suggestionBoxes:hover {
    background-color: #2f808c !important;
  }
  
  .selected {
    background-color: #2f808c !important; 
  }
  .matching-view-btn{
    border: 1px solid #2f808c !important;
    i {
      color: #2f808c !important;
    }
  }
  .tabs-section .nav-tabs .nav-link {
    color: #41464b !important;
}
.tabs-section .nav-tabs .nav-link.active {
    border-bottom: 2px solid #2f808c !important;
    color: #2f808c !important;
    background-color: transparent !important;

}
.nav-tabs .nav-link.active {
    color: #2f808c  !important;
    background-color: #e9e9e9cc !important;
  }
  .selected {
    background-color: #2f808c !important;
    color: #fff !important;
  }
  .outline-blue {
    outline: 1px solid #2f808c !important;
  }
  .upload-status-modal {
    .fa-cloud-arrow-up {
      color: #2f808c !important;
    }
  }
  .active-btn {
      background-color: #2f808c !important;
      i {
        color: #fff !important;
      }
    }
}